<template>
  <div>
    <top-nav :showLogo="false" :title="$t('闪兑')"></top-nav>
    <div class="top-bg">
      <div class="title">
        <p>{{ $t('闪兑') }}</p>
        <p class="subtitle">
          {{ $t('兑换的币种直接进入平台个人中心') }}
        </p>
      </div>
      <img src="../../assets/image/rwa/top-bg.png">
    </div>
    <div class="container">
      <div class="type-cont" v-if="type == 1">
        <div class="type-item type-item-left active-left">{{ $t('拔草值')}}{{ $t('兑换') }}</div>
        <div class="type-item type-item-right" @click="selectType(2)">
          {{ $t('种草值') }}{{ $t('兑换') }}
        </div>
      </div>
      <div class="type-cont" v-else>
        <div class="type-item type-item-left" @click="selectType(1)">{{ $t('拔草值')}}{{ $t('兑换') }}</div>
        <div class="type-item type-item-right active-right">{{ $t('种草值') }}{{ $t('兑换') }}</div>
      </div>

      <div class="form-container" v-if="type == 1">
        <div class="actual-acquisition">
          <div style="float: left"> {{ $t('兑出币种') }}</div>
          <div style="float: right;color: #70521B">{{ $t('可用') }}：{{ userInfo ? userInfo.cny : '-' }} {{
              $t('拔草值')
            }}
          </div>
        </div>
        <div class="input-cont">
          <div class="input-div">
            <div class="title">{{ $t('拔草值') }}</div>
            <input type="text" @focus="promptText =''" v-model="formData.amount"
                   :placeholder="$t('请输入')"/>
            <img v-if="formData.amount" @click="emptyValue" class="empty-icon"
                 src="../../assets/image/rwa/empty-icon.png">
          </div>
          <div class="all-but">
            <span @click="allAmount">{{ $t('全部') }}</span>
          </div>
        </div>
        <div class="prompt-cont">
          {{ promptText }}
        </div>
        <div class="actual-acquisition">
          <div style="float: left">{{ $t('兑入币种') }}</div>
        </div>
        <div class="input-cont">
          <div class="title">BUR</div>
          <div class="input-div">
            <input type="text" disabled v-model="formData.getAmount" placeholder=""/>
            <!--            <img class="empty-icon" src="../../assets/image/rwa/empty-icon.png">-->
          </div>
        </div>
        <!--        <div class="actual-acquisition">-->
        <!--          <div style="float: left;color: #9E7A37">手续费</div>-->
        <!--          <div style="float: right;">5%</div>-->
        <!--        </div>-->

        <div class="actual-acquisition" style="margin-top: 0.68rem">
          <div style="float: left">{{ $t('密码') }}</div>
        </div>
        <div class="input-cont">
          <div class="title">{{ $t('密码') }}</div>
          <div class="input-div">
            <input type="password" @focus="promptPwbText = ''" v-model="formData.pwd"
                   :placeholder="$t('请输入')"/>
            <img class="empty-icon" v-if="formData.pwd" @click="emptyPwd"
                 src="../../assets/image/rwa/empty-icon.png">
          </div>
        </div>
        <div class="edit-pwd" @click="onShowSetPwd">
          {{ userInfo.trade_pwd ? $t('修改密码') : $t('设置密码') }}
        </div>
        <div class="prompt-cont">
          {{ promptPwbText }}
        </div>

        <div class="bottom-cont">
          <router-link to="exchangeRecord/CNY_BUR">
            <div class="order-but">
              <img class="order-icon" src="../../assets/image/rwa/order-icon2.png">
            </div>
          </router-link>
          <div class="confirm-but" @click="postSwap">
            {{ $t('确定') }}
          </div>
        </div>
      </div>
      <div class="form-container" v-else>
        <div class="actual-acquisition">
          <div style="float: left">{{ $t('兑出币种') }}</div>
          <div style="float: right;color: #70521B">{{ $t('可用') }}：{{ userInfo ? userInfo.bur : '-' }} BUR</div>
        </div>
        <div class="input-cont">
          <div class="input-div">
            <div class="title">BUR</div>
            <input type="text" @focus="promptText =''" v-model="formData.amount"
                   :placeholder="$t('请输入')"/>
            <img v-if="formData.amount" @click="emptyValue" class="empty-icon"
                 src="../../assets/image/rwa/empty-icon.png">
          </div>
          <div class="all-but">
            <span @click="allAmount">{{ $t('全部') }}</span>
          </div>
        </div>
        <div class="prompt-cont">
          {{ promptText }}
        </div>
        <div class="actual-acquisition">
          <div style="float: left">{{ $t('兑入币种') }}</div>
        </div>
        <div class="input-cont">
          <div class="title">{{ $t('种草值') }}</div>
          <div class="input-div">
            <input type="text" disabled v-model="formData.getAmount"/>
            <!--            <img class="empty-icon" src="../../assets/image/rwa/empty-icon.png">-->
          </div>
        </div>
        <!--        <div class="actual-acquisition">-->
        <!--          <div style="float: left;color: #9E7A37">手续费</div>-->
        <!--          <div style="float: right;">5%</div>-->
        <!--        </div>-->

        <div class="actual-acquisition" style="margin-top: 0.68rem">
          <div style="float: left">{{ $t('密码') }}</div>
        </div>
        <div class="input-cont">
            <div class="title">{{ $t('密码') }}</div>
            <div class="input-div">
              <input type="password" @focus="promptPwbText = ''" v-model="formData.pwd"
                     :placeholder="$t('请输入')"/>
              <img class="empty-icon" v-if="formData.pwd" @click="emptyPwd"
                   src="../../assets/image/rwa/empty-icon.png">
            </div>
        </div>
        <div class="edit-pwd" @click="onShowSetPwd">
          {{ userInfo.trade_pwd ? $t('修改密码') : $t('设置密码') }}
        </div>
        <div class="prompt-cont">
          {{ promptPwbText }}
        </div>

        <div class="bottom-cont">
          <router-link to="exchangeRecord/BUR_FORAGE">
            <div class="order-but">
              <img class="order-icon" src="../../assets/image/rwa/order-icon2.png">
            </div>
          </router-link>
          <div class="confirm-but" @click="postSwap">
            {{ $t('确定') }}
          </div>
        </div>
      </div>
    </div>
    <div class="operation-success" v-if="showOperationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
<!--          <div class="title"> {{ $t('兑换成功') }}</div>-->
<!--          <div class="text">{{ $t('获得') }}{{ formData.getAmount }} {{ type == 1 ? 'BUR' : $t('拔草值') }}</div>-->
          <div class="title"></div>
          <div class="text"> {{ $t('兑换成功') }}</div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">

        <div class="_but" @click="onShowActivationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>

    <van-popup v-model="showSetPwd" :close-on-click-overlay="false">
      <div class="set-pwd-cont">
        <div class="title">
          <span v-if="userInfo.trade_pwd">{{ $t('修改密码') }}</span>
          <span v-else>{{ $t('请设置密码') }}</span>
        </div>
        <div v-if="userInfo.trade_pwd">
          <div class="input-cont">
            <input :type="type1" @focus="prompt1 = ''" maxlength="6" v-model="formPwd.old_pwd"
                   :placeholder="$t('原密码')"/>
            <img class="show-icon" v-if="type1 == 'text'" @click="type1 = 'password'"
                 src="../../assets/image/index/show_icon.png">
            <img class="show-icon" v-else @click="type1 = 'text'" src="../../assets/image/index/not_show_icon.png">
          </div>
          <div class="error-info">
            {{ prompt1 }}
          </div>
        </div>

        <div class="input-cont">
          <input :type="type2" @focus="prompt2 = ''" maxlength="6" v-model="formPwd.pwd" :placeholder="$t('设置密码')"/>
          <img class="show-icon" v-if="type2 == 'text'" @click="type2 = 'password'"
               src="../../assets/image/index/show_icon.png">
          <img class="show-icon" v-else @click="type2 = 'text'" src="../../assets/image/index/not_show_icon.png">
        </div>
        <div class="error-info">
          {{ prompt2 }}
        </div>
        <div class="input-cont">
          <input :type="type3" maxlength="6" @focus="prompt3 = ''" v-model="formPwd.c_pwd" :placeholder="$t('再次输入密码')"/>
          <img class="show-icon" v-if="type3 == 'text'" @click="type3 = 'password'"
               src="../../assets/image/index/show_icon.png">
          <img class="show-icon" v-else @click="type3 = 'text'" src="../../assets/image/index/not_show_icon.png">
        </div>
        <div class="error-info">
          {{ prompt3 }}
        </div>
        <div class="notes">
          {{ $t('如忘记密码请联系管理员') }}
        </div>

        <div class="operate-cont">
          <div class="operate-but" style="float: left" @click="showSetPwd= false">{{ $t('取消') }}</div>
          <div class="operate-but ok" style="float: right" @click="postTradePwd">{{ $t('确定') }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {postSwap,postTradePwd} from "../../api";
import {mapState} from "vuex";

export default {
  name: "exchange",
  components: {TopNav},
  data() {
    return {
      type: 1,//  1 bur(cny_bur) 2种草 bur_forage
      formData: {
        "amount": "",
        "type": "cny_bur",
        "getAmount": "",
        "pwd": '',
      },
      promptText: '',
      promptPwbText: '',
      showOperationSuccess: false,

      showSetPwd: false,
      type1: 'password',
      type2: 'password',
      type3: 'password',
      formPwd: {
        old_pwd: '',//原密码
        pwd: '',//密码
        c_pwd: '',//确认密码
      },
      prompt1: '',
      prompt2: '',
      prompt3: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.app.userInfo,
    })
  },
  created() {
    this.getUserInfo()
  },
  watch: {
    'formData.amount': function (newData) {
      this.formData.getAmount = newData;
    }
  },
  methods: {
    onShowSetPwd() {
      this.showSetPwd = true
      this.formPwd = {
        old_pwd: '',
        pwd: '',
        c_pwd: '',
      }
      this.prompt1 = '';
      this.prompt2 = '';
      this.prompt3 = '';

    },
    postTradePwd() {
      let that = this;
      if (that.formPwd.old_pwd.length < 1 && that.userInfo.trade_pwd) {
        that.prompt1 = that.$t('请输入原密码')
        return
      } else if (that.formPwd.pwd.length < 6) {
        that.prompt2 = that.$t('请输入6位密码')
        return
      } else if (that.formPwd.pwd != that.formPwd.c_pwd) {
        that.prompt3 = that.$t('两次密码不一致')
        return
      }
      that.prompt1 = ''
      that.prompt2 = ''
      that.prompt3 = ''
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      postTradePwd(that.formPwd)
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              setTimeout(() => {
                that.$toast(that.$t("设置成功"));
              }, 500)
              that.getUserInfo()
              that.showSetPwd = false

            } else {
              setTimeout(() => {
                that.$toast(data.message);
              }, 500)
            }
          })
          .finally(() => {
            that.$toast.clear()
          })
    },

    allAmount() {
      if (this.type == 1) {
        this.formData.amount = this.userInfo.cny
      } else {
        this.formData.amount = this.userInfo.bur
      }
    },
    emptyValue() {
      this.formData.amount = ''
      this.formData.getAmount = ''
      this.promptText = ''
      this.promptPwbText = ''
    },
    emptyPwd() {
      this.formData.pwd = ''
    },
    selectType(type) {
      this.type = type
      this.formData.amount = ''
      this.formData.getAmount = ''
      this.promptText = ''
      this.formData.type = type === 1 ? 'cny_bur' : 'bur_forage'
    },
    postSwap() {
      let that = this;
      if (that.formData.amount.length == 0) {
        that.promptText = that.$t('请输入正确的数量')
        return
      }
      if (!that.formData.pwd) {
        that.promptPwbText = that.$t('请输入密码')
        return
      }
      that.promptText = ''
      that.promptPwbText = ''
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      postSwap(this.formData)
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              that.getUserInfo()
              that.onShowActivationSuccess()
            } else {
              setTimeout(() => {
                that.$toast(data.message);
              }, 500)
            }
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    onShowActivationSuccess() {
      this.showOperationSuccess = !this.showOperationSuccess
      if (!this.showOperationSuccess) {
        this.formData.amount = ''
        this.formData.pwd = ''
      }
    },
    getUserInfo() {
      let that = this
      this.$store.dispatch('app/getUserInfo')
          .then((data) => {
            console.log(data);
            if (data.code == 200) {
              if (data.data.d_pid == 0) {
                that.$router.push({
                  path: '/rwa/binding'
                })
              }else if(!data.data.trade_pwd){
                this.showSetPwd = true;
              }
            }
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
  },

}
</script>

<style scoped lang="less">
.top-bg {
  position: relative;

  .title {
    position: absolute;
    left: 2.29rem;
    bottom: 1.35rem;
    font-size: 1.43rem;
    color: #E7BD71;
  }

  .subtitle {
    font-size: 0.93rem;
    color: #6E6045;
  }

  img {
    width: 100%;
  }
}

.type-cont {
  position: relative;
  overflow: hidden;
  height: 3.14rem;
  top: 0.2rem;

  .type-item {
    position: absolute;
    width: 50%;
    bottom: 0;

    height: 2.57rem;
    box-sizing: border-box;
    background-color: #BE9752;
    line-height: 1.43rem;
    padding: 0.65rem 0;
    color: #6E6045;
    font-size: 1rem;
    text-align: center;


  }

  .type-item-left {
    left: 0;
    border-radius: 1rem 0 0 0;
  }

  .type-item-right {
    right: 0;
    border-radius: 0 1rem 0 0;
  }

  .active-left {
    padding-top: 1.25rem;
    height: 3.14rem;
    color: #333333;
    font-size: 1.14rem;
    border-radius: 1rem 1rem 0rem 0rem;
    background: #E7BD71;
  }

  .active-left::after {
    content: '';
    position: absolute;
    right: -0.5rem;
    bottom: -0.55rem;
    width: 1.5rem;
    height: 3rem;
    transform: rotate(-20deg);
    background: #E7BD71;
    z-index: 1;
  }

  .active-right {
    padding-top: 1.25rem;
    height: 3.14rem;
    color: #333333;
    font-size: 1.14rem;
    border-radius: 1rem 1rem 0rem 0rem;
    background: #E7BD71;
  }

  .active-right::after {
    content: '';
    position: absolute;
    left: -0.5rem;
    bottom: -0.55rem;
    width: 1.5rem;
    height: 3rem;
    transform: rotate(20deg);
    background: #E7BD71;
  }
}

.container {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
}

.form-container {
  padding: 1.68rem 1rem 0.9rem 1rem;
  background: linear-gradient(180deg, #E8BD70 0%, #EDD185 100%);
  //border-radius: 1rem 1rem 0 0;
}

.actual-acquisition {
  width: 90%;
  margin: auto;
  margin-bottom: 0.68rem;
  font-size: 0.86rem;
  color: #333333;
  overflow: hidden;
}

.input-cont {
  padding: 1rem 1.35rem;
  background: #FFF8EA;
  //margin-bottom: 1rem;
  border-radius: 0.71rem;

  .title {
    float: left;
    color: #333;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .input-div {
    text-align: right;
    line-height: 1.29rem;

    input {
      width: 50%;
      text-align: right;
      font-size: 1rem;
      color: #333333;
      border: none;
      background-color: transparent;
    }

    input::-webkit-input-placeholder {
      color: #999999;
    }

    span {
      font-size: 0.79rem;
      color: #666;
    }

    .empty-icon {
      margin-left: 0.2rem;
      float: right;
      width: 1.29rem;
    }
  }

  .all-but {
    text-align: right;
    padding-top: 0.8rem;
    font-size: 0.79rem;
    color: #987534;
  }
}

.bottom-cont {
  margin-top: 8vh;

  .order-but {
    width: 25%;
    display: inline-block;
    padding: 0.79rem 0;
    text-align: center;
    background: #FFDEA3;
    border-radius: 2rem;

    .order-icon {
      height: 1.5rem;
    }
  }

  .confirm-but {
    width: 70%;
    float: right;
    display: inline-block;
    background: #FFFFFF;
    border-radius: 2rem;
    font-size: 1.29rem;
    color: #70521B;
    line-height: 1.5rem;
    text-align: center;
    padding: 0.79rem 0;
  }
}

.prompt-cont {
  width: 90%;
  margin: auto;
  color: #E64937;
  text-align: right;
  font-size: 0.86rem;
  padding-top: 0.23rem;
  height: 1.5rem;
  line-height: 1.5rem;
}



.set-pwd-cont {
  width: 80vw;
  padding: 1.6rem 6%;
  box-sizing: border-box;
  background: #2D2D2D;
  border-radius: 0.71rem;

  .title {
    text-align: center;
    font-size: 1.14rem;
    margin-bottom: 2.2rem;
    color: #E7BD71;
  }

  .input-cont {
    padding: 0.6rem 0 0.6rem 0.9rem;
    background: #2D2D2D;
    margin-top: 1.3rem;
    border-radius: 0;
    border-bottom: 0.03rem solid #101010;

    input {
      width: 80%;
      border: none;
      background-color: #2D2D2D;
    }

    input::-webkit-input-placeholder {
      color: #6E6045;
    }
  }

  .operate-cont {
    overflow: hidden;

    .operate-but {
      width: 43%;
      height: 3rem;
      line-height: 3rem;
      border: 1px solid #E7BD71;
      border-radius: 1.5rem;
      text-align: center;
      font-size: 1rem;
      color: #E7BD71;
    }

    .ok {
      color: #000000;
      border-color: transparent;
      background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
    }
  }

  .notes {
    font-size: 0.86rem;
    color: #625F5F;
    padding-top: 1.8rem;
    padding-bottom: 1.5rem;
    text-align: center;
  }

  .error-info {
    height: 1.5rem;
    font-size: 0.86rem;
    color: #E64937;
    line-height: 1.5rem;
  }
}

.show-icon {
  position: relative;
  top: 0.2rem;
  float: right;
  width: 1.43rem;
}

.edit-pwd {
  padding-top: 0.2rem;
  padding-right: 0.3rem;
  float: right;
  font-size: 0.8rem;
  color: #70521B;
}
</style>
